export function mapUserObject(user, markets) {
  if (!Array.isArray(user.markets.main)) {
    user.markets.main = [user.markets.main];
  }
  delete user.token;

  if (["manager_global", "admin"].includes(user.role)) {
    const ids = markets.map(market => market._id);
    user.markets = {
      main: ids,
      available: ids
    };
  }

  return user;
}

export const ROLES = [
  {
    text: "Analyst",
    value: "analyst",
    level: 3
  },
  {
    text: "Local manager",
    value: "manager_local",
    level: 2
  },
  {
    text: "Global manager",
    value: "manager_global",
    level: 1
  },
  {
    text: "admin",
    value: "admin",
    level: 0
  }
];
