<template>
  <v-card>
    <v-card-title>
      {{ formTitle }}
    </v-card-title>
    <v-card-subtitle>
      <slot name="card-subtitle"></slot>
    </v-card-subtitle>
    <v-card-text class="mt-5">
      <v-form ref="form">
        <v-text-field
          v-model="form.fullname"
          label="Full name"
          :outlined="!preview"
          :disabled="isLoading"
          :readonly="preview"
          :loading="isUserLoading"
          :rules="[
            v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED'),
            rules.cantBeSpacesOnly
          ]"
        />
        <v-text-field
          v-model="form.email"
          label="E-mail"
          :outlined="!preview"
          :disabled="isLoading"
          :readonly="preview"
          :loading="isUserLoading"
          :rules="[v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED')]"
        />
        <v-autocomplete
          v-model="form.role"
          label="Role"
          :outlined="!preview"
          :disabled="isLoading"
          :readonly="preview"
          :loading="isUserLoading"
          :items="roles"
          :rules="[v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED')]"
        />

        <div v-if="!hideMarketsFrom.includes(form.role)">
          <v-autocomplete
            v-model="form.markets.main"
            :items="markets"
            :rules="[v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED')]"
            :disabled="isLoading"
            :readonly="preview"
            :loading="isUserLoading"
            item-value="_id"
            item-text="name"
            outlined
            label="Market core"
          ></v-autocomplete>

          <v-autocomplete
            v-model="form.markets.available"
            :items="additionals"
            :disabled="isLoading"
            :readonly="preview"
            :loading="isUserLoading"
            outlined
            item-value="_id"
            item-text="name"
            chips
            small-chips
            label="Market additional"
            multiple
          ></v-autocomplete>

          <div class="mb-6"></div>
        </div>
      </v-form>

      <v-row>
        <v-spacer />
        <v-col class="text-right">
          <v-btn class="mr-2" color="secondary" outlined @click="handleCancel">
            cancel
          </v-btn>
          <v-btn color="primary" @click="handleSubmit" :disabled="preview">
            {{ submitButtonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { ROLES } from "@/views/Panel/views/Users/helpers";
import { cantBeSpacesOnly } from "@/validators";

export default {
  name: "UserForm",
  props: {
    formTitle: {
      type: String,
      default: "Create new User"
    },
    preview: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isUserLoading: {
      type: Boolean,
      default: false
    },
    user: {
      type: [Object, null]
    },
    submitButtonText: {
      type: String,
      default: "Create"
    }
  },
  data() {
    return {
      hideMarketsFrom: ["admin", "manager_global"],
      rules: {
        cantBeSpacesOnly
      },
      form: {
        fullname: null,
        email: null,
        role: null,
        markets: {
          main: null,
          available: []
        },
        token: null,
        active: true
      },
      baseRoles: ROLES
    };
  },
  computed: {
    ...mapState({
      markets: state => state.api.misc.markets
    }),
    ...mapGetters({
      authUser: "auth/getUser"
    }),
    roles() {
      const powerRoles = ["admin"];
      return this.baseRoles.filter(role => {
        if (
          powerRoles.includes(role.value) &&
          this.authUser.role === role.value
        ) {
          return true;
        }
        const { level } = this.baseRoles.find(
          r => r.value === this.authUser.role
        );

        return role.level > level;
      });
    },
    additionals() {
      return this.markets.map(item => {
        if (
          this.form.markets.main !== null &&
          item._id === this.form.markets.main
        ) {
          item = {
            disabled: true,
            ...item
          };
        }

        return item;
      });
    }
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler() {
        this.setUserForm();
      }
    },
    "form.markets.main"(newValue, oldValue) {
      this.form.markets.available = this.form.markets.available.filter(
        value => value !== newValue && value !== oldValue
      );
      this.form.markets.available.push(newValue);
      // market._id === form.markets.main
    }
  },
  methods: {
    handleCancel() {
      this.$router.push({ name: "PanelUsers" });
    },
    handleSubmit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", JSON.parse(JSON.stringify(this.form)));
      }
    },
    setUserForm() {
      if (this.user) {
        Object.keys(this.form).forEach(key => {
          this.form[key] = this.user[key];

          if (key === "markets") {
            if (typeof this.user.markets.main !== "string") {
              this.form.markets = {
                main: this.user.markets.main[0],
                available: this.user.markets.available
              };
            }
          }
        });
      }
    }
  }
};
</script>
